import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguagePopupService {
  private isOpen = new BehaviorSubject(false);
  isPopup: WritableSignal<boolean> = signal(false);

  isOpen$ = this.isOpen.asObservable();

  openPopup(): void {
    this.isOpen.next(true);
    this.isPopup.set(true);
  }

  openSlideIn(): void {
    this.isOpen.next(true);
    this.isPopup.set(false);
  }

  onClose(): void {
    this.isOpen.next(false);
    this.isPopup.set(false);
  }
}
